(function ($) {

  $(window).ready(function(){
    $( '.price-slider' ).slider({
        range: true,
        min: 0,
        max: 500,
        values: [ 75, 300 ],
        slide: function( event, ui ) {
          $( '.amount' ).html( '$' + ui.values[ 0 ] + ' - $' + ui.values[ 1 ] );
        }
      });
      $( '.amount' ).html( '$' + $( '.price-slider' ).slider( 'values', 0 ) +
        ' - $' + $( '.price-slider' ).slider( 'values', 1 ) );
  });
})(jQuery);